import React from "react";
import { Row, Col, FormGroup, Label, Input, InputProps } from "reactstrap";
import SublimeInfo from "../Help/SublimeInfo";
import SublimeSwitch from "../SublimeSwitch";
import { getImage } from "../../utils/imagesHelper";

import "./index.scss";

interface ISwitchInputProps extends InputProps {
  switchLabel: string;
  inputLabel: string;
  switchId: string;
  inputId: string;
  inputClassName: string;
  placeholder: string;
  checked: boolean;
  value: string;
  infoName?: string;
  children?: any;
  onChange: any;
  onToggle: any;
}

const SwitchInput: React.FunctionComponent<ISwitchInputProps> = ({
  switchLabel = "My switch label",
  inputLabel = "My input label",
  switchId = "customInput",
  inputId = "inputField",
  inputClassName = "",
  placeholder = "My sublime project",
  checked = false,
  value = "",
  infoName = undefined,
  children = undefined,
  type = "text",
  onChange,
  onToggle
}) => {
  const handleToggle = (event: React.MouseEvent<HTMLDivElement>) => {
    //@ts-ignore
    onToggle(event.target.checked);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    onChange(event.target.value);
  };
  const className = checked
    ? "sublime-container sublime-container-active"
    : "sublime-container";
  return (
    <Row className={className}>
      <Col xs={12} md={12}>
        <FormGroup className="flex-row">
          <Label className="flex-row flex-center-items">
            <SublimeSwitch
              className={inputClassName}
              onChange={handleToggle}
              checked={checked}
              name={switchId}
              id={switchId}
            />
            {switchLabel && switchLabel}
          </Label>
          {infoName && (
            <SublimeInfo target={switchId} imgSrc={getImage(infoName)} />
          )}
        </FormGroup>
      </Col>
      <Col
        xs={12}
        md={12}
        className={
          checked ? "sublime-switch-content-active" : "sublime-switch-content"
        }
      >
        <FormGroup>
          <Label for={inputId}>{inputLabel} :</Label>
          <Input
            onChange={handleTextChange}
            value={value}
            type={type}
            name={inputId}
            id={inputId}
            placeholder={placeholder}
          />
        </FormGroup>
        <div
          className={
            children && checked
              ? "sublime-switch-content-active"
              : "sublime-switch-content"
          }
        >
          {children}
        </div>
      </Col>
    </Row>
  );
};

export default SwitchInput;
