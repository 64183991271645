import React, { useState, useRef } from "react";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { GiInfo } from "react-icons/gi";
import Theme from "../../../utils/theme";
import useOutsideClickNotifier from "../../../utils/useOutsideClick";

interface ISublimeInfoProps {
  imgSrc: string;
  target: string;
}

const SublimeInfo: React.FunctionComponent<ISublimeInfoProps> = ({
  imgSrc = "http://ekladata.com/vv20I9BiuK-5M7lynE06NVEZZq0.jpg",
  target = "name"
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const innerRef = useRef(null);

  const onClickAction = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  };

  const onClickOut = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsOpen(false);
  };

  useOutsideClickNotifier(onClickOut, innerRef);
  return (
    <div ref={innerRef} onClick={onClickAction} style={styles.container}>
      <GiInfo size="18px" color={Theme.sublime.color.mainGreen} />
      <Popover
        hideArrow
        flip={false}
        placement="top-start"
        isOpen={isOpen}
        target={target}
      >
        <PopoverHeader>Sublime info</PopoverHeader>
        <PopoverBody style={{ width: "auto" }}>
          <img width="800px" src={imgSrc} alt={target} />
        </PopoverBody>
      </Popover>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .popover{
          max-width: 1000px;
        }
      `
        }}
      />
    </div>
  );
};

const styles = {
  container: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    marginLeft: "5px"
  }
};

export default SublimeInfo;
