import React from "react";
import Switch from "react-switch";
import Theme from "../../utils/theme";

import "./index.scss";

interface ISublimeSwitchProps {
  onChange: any;
  checked: boolean;
  name: string;
  className: string;
  id?: string;
}

const SublimeSwitch: React.FunctionComponent<ISublimeSwitchProps> = ({
  onChange = () => {},
  checked = false,
  name = "sublime",
  className = "",
  id,
  ...rest
}) => {
  return (
    <Switch
      {...rest}
      id={id}
      onColor={Theme.sublime.color.mainGreen}
      offColor={Theme.sublime.color.mainRed}
      className={`${className || ""} sublime-switch`}
      onChange={isChecked => onChange({ target: { name, checked: isChecked } })}
      checked={checked}
    />
  );
};

export default SublimeSwitch;
