import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./index.scss";

const alertBox = (title: string, message: string, onConfirm: any) => {
  confirmAlert({
    title,
    message,
    buttons: [
      {
        label: "Ok",
        onClick: onConfirm
      }
    ]
  });
};

export default alertBox;
