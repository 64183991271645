import React, { useEffect } from "react";

export default function useOutsideClickNotifier(
  onOuterClick: any,
  innerRef: any
) {
  useEffect(() => {
    // only add listener, if the element exists
    if (innerRef.current) {
      //@ts-ignore
      document.addEventListener("click", handleClick);
    }

    // unmount previous first in case inputs have changed
    //@ts-ignore
    return () => document.removeEventListener("click", handleClick);

    function handleClick(e: React.MouseEvent<HTMLDivElement>) {
      innerRef.current &&
        !innerRef.current.contains(e.target) &&
        onOuterClick(e);
    }
  }, [onOuterClick, innerRef]);
  return null;
}
