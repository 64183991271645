import React from "react";
import { Spinner } from "reactstrap";

interface ISublimeSpinnerProps {
  label: string;
}

const SublimeSpinner: React.FunctionComponent<ISublimeSpinnerProps> = ({
  label
}) => {
  return (
    <div style={{ position: "absolute", top: "50vh", left: "50vw" }}>
      <Spinner color="primary" />
      {label && <p>{label}</p>}
    </div>
  );
};

export default SublimeSpinner;
